export default {
  Math: {
    distance2D (x1, y1, x2, y2) {
      const x = x1 - x2
      const y = y1 - y2
      return Math.sqrt(x * x + y * y)
    },
    getAngle (x1, y1, x2, y2) {
      return (Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI)
    }
  },

  isValidMove (angle) {
    const validAngles = [0, 45, 90]
    return validAngles.findIndex(a => a === angle) >= 0
  },

  lettersBetween (x1, y1, x2, y2, gridSize) {
    // console.log(`${x1}::${y1}::${x2}::${y2}`)
    let dx; let dy; let x; let y; let tmp; let rev = false
    const indices = []
    if (x1 > x2) {
      tmp = x1
      x1 = x2
      x2 = tmp
      rev = true
    }
    if (y1 > y2) {
      tmp = y1
      y1 = y2
      y2 = tmp
      rev = true
    }
    dx = x2 - x1
    dy = y2 - y1

    if (x1 != x2) {
      for (x = x1; x <= x2; x++) {
        y = y1 + dy * (x - x1) / dx
        if (!isNaN(y)) {
          indices.push(y * gridSize + x)
        }
      }
    } else {
      for (y = y1; y <= y2; y++) {
        x = x1 + dx * (y - y1) / dy
        if (!isNaN(x)) {
          indices.push(y * gridSize + x)
        }
      }
    }
    return { indices: indices, reverse: rev }
  }
}
