<template>
  <v-row class="ma-6" align="center" justify="center">
    <v-btn
      text
      color="primary"
      @click="hasHistory() ? $router.go(-1) : $router.push('/')"
    >
      &larr;{{ $t('Go Back') }}
    </v-btn>
  </v-row>
</template>
<script>
export default {
  methods: {
    hasHistory() {
      return window.history.length > 2
    }
  }
}
</script>
